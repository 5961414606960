import styled from 'styled-components';
import caseBg from 'common/src/assets/image/hosting/Background/case_study2.webp';

const MiscWrapper = styled.div`
  // background-image: linear-gradient(180deg, rgba(8, 18, 117, 0.3) 20%, #f9fbfd);
  background:#fff;
  height: 100%;
  min-height: calc(100vh - 36px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  .text_content {
    p,
    li {
      color: #0f2137;
      font-weight: bold;
      font-family: inherit;
      font-size: 16px;
    }
    p {
      text-align: justify;
    }
    li {
      list-style-type: disc;
      margin-left: 35px;
    }
  }
  button {
    border: none;
  }
  .btn.thanks {
    max-width: 200px;
  }
  &::before {
    content: "";
    // background-image: url(${caseBg});
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    background-size: cover;
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
  }
  img {
    margin: 40px auto;
    width: 100%;
    height: 100%;
    max-width: 450px;
    object-fit: contain;
  }
  &.misc_thanks_wrapper {
    min-height: calc(100vh - 36px - 300px);
    .Cta_container {
      padding: 30px;
    }
  }
  @media only screen and (min-width: 1025px) and (min-height: 700px) {
    min-height: auto;
    min-height: 700px;
    &.misc_thanks_wrapper {
      min-height: auto;
      min-height: 700px;
    }
  }
  @media only screen and (max-width: 575px) {
    .text_content {
      li {
        margin-left: 20px;
      }
      .heading {
        text-align: center;
      }
    }
  }
`;

export default MiscWrapper;
