import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';
import TopRibbon from '../../containers/Hosting/TopRibbon';

import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Extlink from 'common/src/components/Link';
import Container from 'common/src/components/UI/Container';

import ContactSection2 from '../../containers/Hosting/Contact2/Misc';
import HyperautomationGot from 'common/src/assets/image/hosting/news/Hyperautomation-Just-Got.png';
import founder1 from 'common/src/assets/image/hosting/Team/Raghu.jpg';
import founder2 from 'common/src/assets/image/hosting/Team/Baskar.jpg';
import MiscWrapper from './misc.style';
import { useEffect, useState } from 'react';
import Ctasection from '../../containers/Hosting/Ctasection';

const MiscSection1 = ({
  name,
  sectionWrapper,
  row,
  row1,
  col,
  col2,
  title,
  description,
  centeritems,
  bold_description,
  topmargin,
  linktext,
  buttonbox,
  innerrow,
  centertext,
  center_small_text,
  margintop,
  centertopic,
}) => {
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Rapid Acceleration Partners Secures Pre-Series A Funding from Artha99 AIF Investment Advisors to Democratize AI-First Hyperautomation Solutions"
          description="Rapid Acceleration Partners (RAP), an AI-first hyperautomation platform company, announces Pre-Series A funding from Artha99 AIF Investment advisors. RAP aims to help companies improve productivity and efficiency using its low-code hyperautomation platforms RAPFlow and RAPBot. Join RAP on its mission to democratize AI and intelligent process automation for companies of all sizes."
        />
        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="CaseStudyPage">
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <MiscWrapper>
            <Box {...sectionWrapper}>
              <Container>
                <Box {...row1}>
                  <Box {...innerrow}>
                    <Fade left cascade>
                      <Box>
                        <Box {...row}>
                          <Box {...col}>
                            <img
                              src={HyperautomationGot}
                              style={{ maxWidth: '100%' }}
                              alt="analytics insight magazine certificate"
                            />
                          </Box>
                        </Box>
                        <Heading
                          {...title}
                          content="RAP Secures Funding From Artha99 
                          Investment Advisors
                           "
                        />

                        <Text
                          {...description}
                          content="Rapid Acceleration Partners (RAP), an AI-first hyperautomation platform 
                          company announced the securing of a Pre-Series A funding from Artha99 
                          AIF Investment advisors."
                        />
                        <br />
                        <br />
                        <Text
                          {...description}
                          content="With its low-code hyperautomation platforms RAPFlow and RAPBot, RAP 
                          aims to help companies solve the challenge of unstructured content and 
                          improve productivity and efficiency using an AI first approach. "
                        />
                        <br />
                        <br />
                        <Text
                          {...description}
                          content="Speaking of the development, Raghuraman Ramamurthy, CEO of RAP said
                          “We are delighted that Artha99 chose to invest in us, and I welcome them 
                          to the RAP family. We look forward to working closely with Artha99 over 
                          the coming years as we build RAP into a formidable player in the 
                          hyperautomation space. The fresh round of funding is further 
                          reinforcement of the potential of AI to disrupt the automation space and 
                          the value that RAP has delivered to its customers "
                        />
                        <br />
                        <br />
                        <Text
                          {...description}
                          content="Mr. Vignesh Shankar, Managing Partner, Artha99 is excited about 
                          becoming a part of RAP on its growth journey and said “They have a 
                          robust technology team with strong product knowledge”, and adds that, 
                          “considering the current industry-wide focus on driving efficiencies and 
                          cost optimization, the investment in RAP is a timely one for the fund.”
                          The competition to reduce cycle times and offer better customer 
                          experience has led to an increase in the demand for hyper-automation 
                          solutions worldwide. The global market for hyperautomation is currently 
                          valued at USD 549.3 million and this figure is predicted to skyrocket over 
                          the next 8 years as it's expected to reach a staggering USD 2,133.9 
                          million by 2029 - representing a strong CAGR of 22.79%. "
                        />
                        <br />
                        <br />
                        <Text
                          {...description}
                          content="Rapid Acceleration Partners helps enterprises unlock their full potential 
                          through hyperautomation solutions. RAP is on a mission is to democratize 
                          AI and intelligent process automation for companies of all sizes. To 
                          achieve this goal, RAP has developed two powerful Low-Code/No-Code 
                          platforms - RAPFlow and RAPBot. RAPFlow is a AI pipeline building and 
                          orchestration platform, while RAPBot is an intelligent RPA platform. "
                        />
                        <br />
                        <br />
                        <Text
                          {...description}
                          content=" RAP has successfully implemented its solutions in the hospitality, shared 
                          services, mortgage and financial institutions, automotive, manufacturing, 
                          and insurance industries."
                        />
                        <br />
                        <br />
                        <Text
                          {...bold_description}
                          style={{ fontSize: '20px' }}
                          content="About Artha99 AIF investment advisors
                        
                           "
                        />
                        <br />
                        <Text
                          {...description}
                          content=" Artha99 AIF investment advisors is an India focussed SEBI registered CAT 
                          1 venture capital fund. This fund is focused on identifying, investing and 
                          catalysing businesses for the Indian growth century."
                        />
                      </Box>
                    </Fade>

                    {/* <Box {...row}>
                      <Box {...col2}>
                        <Fade left cascade>
                          <img
                            src={founder1}
                            style={{ width: '150px', display: 'block' }}
                          />
                          <Text
                            {...centertext}
                            content="Raghuraman Ramamurthy"
                          />
                          <Text
                            {...center_small_text}
                            content="Founder & CEO, RAP."
                          />
                          <Text
                            {...centeritems}
                            content="“RAP was founded with a vision to democratize AI and IPA for everyone, opening the door to the future of hyperautomation. It has been quite a ride since our early days with a handful of pilot projects to carving out a niche as a recognized player in the industry over the last 3 years. It is truly gratifying to see the hard work and long nights paying off. More than that, it is invigorating to see what the future holds for us and our clients in this new, exciting chapter of disruptive innovation that is changing how companies work.” Raghuraman Ramamurthy, Founder & CEO, RAP."
                          />
                        </Fade>
                      </Box>
                      <Box {...col2}>
                        <Fade right cascade>
                          <img
                            src={founder2}
                            style={{ width: '150px', display: 'block' }}
                          />
                          <Text {...centertext} content="Baskar Agneeswaran" />
                          <Text
                            {...center_small_text}
                            content="Founder & Director, RAP"
                          />
                          <Text
                            {...centeritems}
                            content="“I’m delighted that RAP has been recognized among the Top 10 Most Valuable Digital Transformation Companies in 2020. Automation is the touchstone of the future, and our mission is to empower companies worldwide with hyperautomation. The future of this disruptive technology is exciting and RAP will play a significant role as a digital transformation partner for companies worldwide.” Baskar Agneeswaran, Founder & Director, RAP"
                          />
                        </Fade>
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
              </Container>
            </Box>
          </MiscWrapper>
          <Ctasection name="Home" />
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

MiscSection1.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

MiscSection1.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: '100px',
    pb: ['0', '0', '50px', '50px', '50px'],
    id: 'misc_section',
  },
  row1: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  innerrow: {
    width: ['100%', '100%', '100%', '70%', '70%'],
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '100%', '100%'],
  },
  col2: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  title: {
    fontSize: ['30px', '30px', '35px', '35px', '35px'],
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '30px',
    textAlign: 'center',
    className: 'heading',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
    display: 'inline',
  },
  bold_description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    fontWeight: 'bold',
    mb: '10px',
    display: 'inline',
  },
  centeritems: {
    textAlign: 'justify',
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
  centertext: {
    textAlign: 'center',
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '8px',
  },
  center_small_text: {
    textAlign: 'center',
    fontSize: ['14px', '14px', '14px', '14px', '14px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
  topmargin: {
    mt: '15px',
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
  linktext: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    color: 'blue',
  },
  buttonbox: {
    width: [1, 1, 0.2, 0.2, 0.2],
    flexBox: true,
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    mt: '20px',
  },
  margintop: {
    mt: '50px',
  },
  centertopic: {
    textAlign: 'center',
  },
};

export default MiscSection1;
